console.log('WE LOVE TYPO3');
$(document).ready(function() {
    var isOpen = false;
    $('.navigation__trigger').click(function() {
        if (isOpen) {
            $('.navigation__megamenu').fadeOut('fast');
            $('.navigation__trigger').css('background-image', 'url(/typo3conf/ext/joitzalangbecker2022/Resources/Public/Icons/Menu.svg)');
            $('#home .navigation__trigger').css('background-image', 'url(/typo3conf/ext/joitzalangbecker2022/Resources/Public/Icons/Menu-over.svg)');
            $('#home .logo__container--main').fadeOut('fast');
            //$('#page__header').css('background-color', 'transparent' , 300);
            $('#home .logo__container--home').fadeIn('slow');
            $('#visionen .logo__container--main').fadeOut('fast');
            $('#visionen .logo__container--home').fadeIn('slow');

        } else {
            $('.navigation__megamenu').fadeIn('fast');
            $('.navigation__trigger').css('background-image', 'url(/typo3conf/ext/joitzalangbecker2022/Resources/Public/Icons/Kreuz.svg)');
            $('#home .logo__container--home').fadeOut('fast');
            //$('#page__header').css('background','#fff', 300);
            $('#home .logo__container--main').fadeIn('slow');
            $('#visionen .logo__container--home').fadeOut('fast');
            $('#visionen .logo__container--main').fadeIn('slow');
        }
        isOpen = !isOpen;
    });
    var swiper = new Swiper(".mySwiper", {
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

});

const swiper = new Swiper('.heroslider', {
    // Optional parameters
    // direction: 'vertical',
    direction: 'horizontal',
    loop: true,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    },
});

window.onscroll = function() {scrollFunction()};
function scrollFunction() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
        document.getElementById("pagenavigation").classList.add("scrolled");
    } else {
        document.getElementById("pagenavigation").classList.remove("scrolled");
    }
}

function goBack() {
    window.history.back();
}